<template>
  <div id='Pc' v-html="html">

  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  data () {
    return {
      html: ''
    };
  },
  computed: {

  },
  created () {

  },
  mounted () {
    this.detail();
  },
  methods: {
    detail () {
      this.$http.get('homePage/pc').then((res) => {
        this.html = res.data;
      });
    }
  }
};
</script>

<style scoped lang="less">

</style>
